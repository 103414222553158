export default {
  user: [],
  banners: [],
  sites: [],
  sites_credit: [],
  creditincrese: false,
  creditdecrese: false,
  lastbonus: [],
  alertwithdraw: false,
  promotions: [],
  promotion: [],
  gotpro: false,
  loading: false,
  ref: undefined,
  refsite: undefined,
  coin_voice: false,
  rewards: [],
  jackpot: []
}
